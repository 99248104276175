@mixin sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: white;
}

.menu {
  padding-top: 40px;
  padding-bottom: 48px;
  .itemList {
    margin-top: 24px;
    width: 100%;
    padding-bottom: 64px;
  }
}

.page {
  background: #fff;
}

@media screen and (max-width: 768px) {
  .menu {
    margin-top: 0;
    padding-top: 24px;
    .itemList {
      margin-top: 16px;
    }
  }
}
